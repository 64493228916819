import React from "react";
import logo from "./logo-elykoid.jpg";
import logoFTB from "./logo-FTB.png";
import logoQualiopi from "./logo-qualiopi.png";
import "./App.css";
import HttpsRedirect from "react-https-redirect";

function App() {
  return (
    <HttpsRedirect>
      <div className="App">
        <div className="App-header">
          <img src={logo} className="App-logo" alt="elykoid" />
          <p className="subtitle">
            Numérique, Environnement, Conseil & Formation
          </p>
          <a
            className="contact"
            href="mailto:contact@elykoid.com"
            target="_blank"
            rel="noopener noreferrer">
            Contactez-nous
          </a>

          <div className="logo-bas">
            <div className="logo-box">
              <img src={logoFTB} className="logoFTB" alt="french tech brest+" />
            </div>
            <div className="logo-box">
              <img src={logoQualiopi} className="logoCertif" alt="qualiopi" />
              <p className="texte-certificat">
                La certification qualité a été délivrée au titre de la catégorie
                d'action suivante : L.6313-1-1° Actions de formation
              </p>
              <a
                className="certificat"
                href="https://certifopac.fr/qualiopi/certification/verification/?siren=853020865"
                target="_blank"
                rel="noopener noreferrer">
                Afficher le certificat
              </a>
            </div>
          </div>
        </div>
      </div>
    </HttpsRedirect>
  );
}

export default App;
